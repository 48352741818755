import React from "react";
import PropTypes from "prop-types";

const ProgressCircle = ({ completed, total }) => {
  const radius = 33;
  const circumference = radius * 2 * Math.PI;
  const percentComplete = completed / total;

  return (
    <div className="progress-circle">
      <svg aria-hidden="true" height="74" role="presentation" width="74">
        <circle cx="37" cy="37" fill="transparent" r={radius} strokeWidth="2" />
        <circle
          cx="37"
          cy="37"
          fill="transparent"
          r={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeWidth="4"
          style={{ strokeDashoffset: circumference * (1 - percentComplete) }}
          data-testid="progress-circle"
        />
      </svg>
      <p>
        {completed}/{total}
      </p>
    </div>
  );
};

ProgressCircle.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ProgressCircle;
