import React from "react";
import PropTypes from "prop-types";

const CrossCircleIcon = ({
  alt = "Cross Circle",
  className = "",
  size = 24,
  onClick = () => {},
}) => (
  <svg
    aria-label={alt}
    className={`wink-icon ${className}`}
    width={size}
    height={size}
    onClick={onClick}
    viewBox="0 0 24 24"
    aria-labelledby="CrossCircleIcon"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="CrossCircleIcon">{alt}</title>
    <path d="M10.586 12L6.793 8.207l1.414-1.414L12 10.586l3.793-3.793 1.414 1.414L13.414 12l3.793 3.793-1.414 1.414L12 13.414l-3.793 3.793-1.414-1.414L10.586 12z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0-2a9 9 0 100-18 9 9 0 000 18z"
    />
  </svg>
);

CrossCircleIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default CrossCircleIcon;
